<template>
  <div class="social-space-add-section position-relative mb-4">
    <div class="select-like" @click="toggleIsOpen">
      <div class="d-flex justify-content-between align-items-center h-100">
        <div></div>
        <div class="title d-flex align-items-center">
          <i class="ti-plus icon mr-1" />
          Add Section
        </div>
        <ion-button class="arrow-btn">
          <i v-if="isOpen" class="ti-angle-up icon" />
          <i v-else class="ti-angle-down icon" />
        </ion-button>
      </div>
    </div>
    <div v-if="isOpen" class="options-wrapper">
      <div class="options">
        <div v-for="(value, key, index) of fullPossibleSectionsLabelMap" :key="key">
          <div class="one-option d-flex align-items-center" @click="addSectionAction(key)">
            {{ value }}
          </div>
          <hr v-if="isNotLast(index, possibleSectionsLabelMap)" class="m-0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { possibleSectionsLabelMap } from '@/shared/statics/socialspace';

const { addSection, socialSpace } = socialSpaceCreatorStore();

const hasValue = (value: any) => {
  return value || value === '';
};

const fullPossibleSectionsLabelMap = computed(() => {
  const existingKeys = socialSpace?.value?.extra.order.filter((key: string) => hasValue(socialSpace?.value?.[key]));
  const nonRepeatedPossibleSectionsLabelMap = Object.keys(possibleSectionsLabelMap).reduce((acc, curr) => {
    if (existingKeys?.includes(curr)) return acc;
    return {
      ...acc,
      [curr]: possibleSectionsLabelMap[curr],
    };
  }, {});

  return {
    text: 'Custom Section',
    ...nonRepeatedPossibleSectionsLabelMap,
  };
});

const isOpen = ref(false);

const toggleIsOpen = () => {
  isOpen.value = !isOpen.value;
};

const addSectionAction = (key: string) => {
  addSection(`${key}`);
  toggleIsOpen();
};

const isNotLast = (index: number, object: any) => {
  return index < Object.keys(object).length;
};
</script>

<style lang="sass" scoped>
.dark .social-space-add-section .options-wrapper .options
  background-color: #17074C !important
.social-space-add-section
  .select-like
    cursor: pointer
    background: #fff
    border: 3px solid #214163
    height: 50px
    border-radius: 12px
    .title
      font-weight: bold
      font-size: 18px
    .icon
      font-weight: bolder
      font-size: 16px
    .arrow-btn
      height: 45px
      margin-right: -1px
      border-left-style: solid
      border-left-color: #214163
      border-left-width: 3px
      border-radius: 11px
      border-top-width: 3px
      --border-radius: 8px 9px 10px 8px
      width: 49px
      border-top-style: solid
      border-top-width: 0.5px
      border-top-color: #214163
      border-bottom-style: solid
      border-bottom-width: 0.5px
      border-bottom-color: #214163
      --background: linear-gradient(to bottom, rgb(217 26 216) 0%, rgb(204 34 218) 49%, rgb(179 52 227) 100%)
    &:hover
      opacity: 0.8
  .options-wrapper
    padding: 20px
    .options
      padding: 10px
      background: #fff
      border-radius: 20px
      .one-option
        height: 40px
        padding: 0 15px
        cursor: pointer
        &:hover
          background: #EFE2FF
          font-weight: bold
.dark .arrow-btn
  height: 45px !important
  border-left-color: #17074c !important
  --border-radius: 9px 9px 9px 7px !important
  border-top-color:  #17074c !important
  border-bottom-color:  #17074c !important
  border-bottom-left-radius: 9px !important
</style>
