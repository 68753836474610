<template>
  <div class="social-space-sections position-relative">
    <Sortable
      v-if="ready"
      id="sections"
      :key="`_${rerenderKey}`"
      :disabled="false"
      item-key="key"
      tag="div"
      :list="reorderedSections"
      :options="sortableOptions"
      @end="handleReorder"
    >
      <template #item="{ element, index }">
        <ReorderableSection
          v-if="hasValue(element.value, element.key)"
          :key="`_${element.key}`"
          class="mb-4"
          :title="titleGetter(element.key, element.value)"
          :index="index"
          :used-key="element.key"
        >
          <div v-if="element.value.type === 'text'" class="text">
            <Text :key="rerenderKey" :value="element.value" :used-key="element.key" />
          </div>
          <div v-if="element.key === 'description'" class="text">
            <BodyOnly
              :key="rerenderKey"
              :value="element.value"
              :used-key="element.key"
              placeholder="Describe the magic of your social space. What makes it the place to be?"
            />
          </div>
          <div v-if="element.key === 'visual_collections_page'" class="text">
            <InlineGallery
              :used-key="'social_space_gallery'"
              :is-editor="true"
              parent-type="world"
              :parent-id="socialSpace?.id"
              @touched="markAsDirty"
              key="inline-gallery-editorss"
            />
          </div>
          <div v-if="element.key === 'rooms'" class="text">
            <chatrooms-reorderable-section title="Chatroom" :used-key="element.key" />
          </div>

          <div v-if="element.key === 'character_worlds'" class="text">
            <characters :value="element.value" :used-key="element.key" />
          </div>
        </ReorderableSection>

        <div v-if="element.key === 'extra'">
          <div v-for="(extraValue, extraKey, extraindex) in reorderedExtraSections" :key="extraKey" class="mb-4">
            <ReorderableSection
              v-if="hasValue(extraValue, extraKey)"
              class="mb-4"
              :title="titleGetter(extraKey, extraValue)"
              :index="extraindex + index"
              :used-key="extraKey"
            >
              <div v-if="extraValue.type === 'text'" class="text">
                <Text :key="rerenderKey" :value="extraValue" :used-key="extraKey" />
              </div>
            </ReorderableSection>
          </div>
        </div>
      </template>
    </Sortable>
  </div>
</template>

<script lang="ts" setup>
import ReorderableSection from './ReorderableSection.vue';
import ChatroomsReorderableSection from './ChatroomsReorderableSection.vue';
import Text from './Text.vue';
import BodyOnly from './BodyOnly.vue';
import Characters from './Characters.vue';
import InlineGallery from '@/shared/components/Gallery/InlineGallery.vue';
import { possibleSectionsLabelMap } from '@/shared/statics/socialspace';
import { capitalizeFirstLetter } from '@/shared/utils/string';
import { socialSpaceCreatorStore } from '@/shared/pinia-store/social-space-creator';
import { unreorderablesocialspaceKeys } from '@/shared/statics/constants';
import { mainStore } from '@/shared/pinia-store/main';

const { changeKey, socialSpace, markAsDirty } = socialSpaceCreatorStore();
const ready = ref(true);

const sortableOptions = {
  handle: '.section-handle',
  animation: 150,
};

const titleGetter = (key: string, value: any) => {
  return possibleSectionsLabelMap[key] || capitalizeFirstLetter(value.type) || '';
};

const hasValue = (value: any, key: any) => {
  if (key === 'extra') return false;
  if (key === 'rooms') return true;
  return value || value === '';
};

const rerenderKey = ref(0);

const isDarkMode = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(isDarkMode, () => {
  rerenderKey.value++;
});

const possibleSections = computed(() => {
  return omit(socialSpace?.value, unreorderablesocialspaceKeys as string[]);
});

const computedOrder = computed(() => {
  return socialSpace?.value?.extra.order || Object.keys(possibleSections.value);
});

const reorderedSections = computed(() => {
  const order: string[] = computedOrder.value;

  // const reorderedSections = order.reduce((acc: SocialSpace, current) => {
  //   if (!hasValue(possibleSections.value[current], '') && !hasValue(possibleSections.value.extra[current], '')) {
  //     return acc;
  //   }

  //   return {
  //     ...acc,
  //     //[current]: possibleSections.value[current],
  //     [current]: !hasValue(possibleSections.value[current], '')
  //       ? possibleSections.value.extra[current]
  //       : possibleSections.value[current],
  //   };
  // }, {} as SocialSpace);
  const reorderedSectionsList = order.map((key) => {
    return {
      key,
      value: !hasValue(possibleSections.value[key as any], '')
        ? possibleSections.value.extra[key as any]
        : possibleSections.value[key as any],
    };
  }) as { key: string; value: any }[];
  return reorderedSectionsList;
});

const reorderedExtraSections = computed(() => {
  return omit(reorderedSections.value.extra, 'quote', 'order');
});
// const handleReorder = (ev: CustomEvent) => {
//   const order: string[] = socialSpace?.value?.extra.order || Object.keys(possibleSections.value);
//   const { from, to } = ev.detail;
//   order.splice(to, 0, order.splice(from, 1)[0]);
//   changeKey('order', order);
//   ev.detail.complete();
//   ev.stopPropagation();
//   ready.value = false;
//   nextTick(() => {
//     ready.value = true;
//   });
// };
const handleReorder = (e: any) => {
  const toModify = socialSpace?.value?.extra.order || Object.keys(possibleSections.value);
  toModify.splice(e.newIndex, 0, toModify.splice(e.oldIndex, 1)[0]);
  changeKey(
    'order',
    toModify.filter((item: any) => item)
  );
  rerenderKey.value++;
};
</script>

<style lang="sass" scoped>
.social-space-sections
  .text
    .editor
      height: 200px
      ::v-deep
        .tox
          height: 200px !important
          border: 0
          border-radius: 10px
    .one-input
      margin-bottom: 10px
      border-radius: 10px !important
      ::v-deep
        input, textarea
          border: 0 !important
        textarea
          padding: 12px
</style>
